/* You can add global styles to this file, and also import other style files */
@import "styles-variables";
@import '~bootstrap/dist/css/bootstrap.min.css';

$mdc-theme-primary: #cacaca; // primary color example
$mdc-theme-secondary: #3f51b5; // secondary color example
//rgba(63, 81, 181, 1)

// my green: #90c418  rgb(144,196,24)
@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500&display=swap');

body {
  margin: 0;
  padding: 0;
  //background-color: whitesmoke;
  //background-attachment: fixed;
  background: url('../assets/images/body_bg.jpg') round;
  background-size: cover;
}

body, html {
  height: 100%;
  font-family: 'Heebo', sans-serif;
}

.hide {
  opacity: 0;
}

.opacity33 {
  opacity: .333;
}

.opacity5 {
  opacity: .5;
}

.no_border_b {
  border-bottom: unset !important;
}

.auth_main,
.settings_main,
.main_content_mid,
.home_main,
.orders_main,
.tourview_main,
.component_main,
.createtour_main {
  text-align: -moz-center;
  text-align: -webkit-center;
}

.auth__container,
.createtour__container {
  margin: 0 1em;
  max-width: 600px;
}

.settings__container,
.component__container,
.layout__container,
.home__container,
.orders__container {
  margin: 0 1em 0;
  text-align: left;
}

.settings__container {
  background-color: white;
}

.comp_head {
  background: white;
  border-bottom: solid 1px #ccc;
}

.createtour_main {
  min-height: 100%;
}

.noselect {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}

.rotate90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.btn_ok_fixed {
  position: fixed;
  bottom: 3%;
  align-self: flex-end;
}

.blue_ico {
  color: $link-color !important;
}

.btn_save {
  background-color: $link-color;
  color: white;
  padding: .4em;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
  min-width: 95px;
  text-align: center;
}

.add_btn {
  color: rgba(0, 0, 0, .8);
}

.link {
  text-decoration: unset;
  font-style: unset;
}

.progress_bar {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 1000;
}

.pointer {
  cursor: pointer !important;
}

.here_link {
  color: #3f51b5;
  text-decoration: underline;
  cursor: pointer;
  padding: 0 .125em;
}

.hidden {
  display: none !important;
}

.half-hidden {
  opacity: .5 !important;
}

.line_through {
  text-decoration: line-through;
}

.footer-nav {
  position: sticky;
  bottom: 2em;
  width: 100%;
  //height: 65px;
  text-align: right;
  transition: bottom .3s;
}

.footer-actions {
  padding: 3em .5em 0;
}

.rec_online {
  font-weight: 600 !important;
  color: #008000 !important;
}

.dot_act {
  height: 20px !important;
  width: 20px !important;
  font-size: 1.333em !important;
}

.btn_sync {
  font-size: 18px;
  cursor: pointer;
  color: rgba(0, 0, 0, .6);
}

.fab-btn {
  z-index: 3;
}

.check_text {
  min-width: 120px;
  max-height: 40px;
  width: 100%;
  background-color: white;
}

.no_overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clear_city {
  font-size: 1.5em;
  color: rgba(0, 0, 0, .66);
  margin: .5em 0 0 0;
  cursor: pointer;
}

.refresh_icon {
  color: rgba(0, 0, 0, .66);
  cursor: pointer;
  align-self: center;
  margin-right: .5em;
  min-width: 1em;
}

.no-padding {
  padding: 0;
}

.app-loading {
  width: 100%;
  height: 100%;
  text-align: center;
}

.planafy_loading {
  position: relative;
  top: 20%;
  max-width: 270px;
  min-width: 250px;
  -webkit-animation: opa 1s infinite alternate;
  -moz-animation: opa 1s infinite alternate;
  -o-animation: opa 1s infinite alternate;
  animation: opa 1s infinite alternate;
}

.logo-loading {
  width: 70%;
  max-width: 225px;
  min-width: 200px;
  position: relative;
  top: 25%;
  -webkit-animation: opa 1s infinite alternate;
  -moz-animation: opa 1s infinite alternate;
  -o-animation: opa 1s infinite alternate;
  animation: opa 1s infinite alternate;
}

.ico_active {
  color: #40a040 !important;
}

.ico_inactive {
  color: rgb(220, 220, 220);
}

.hide_ico {
  color: #b5b5b5;
}

.tour_hid {
  background-color: rgba(255, 255, 255, .7);
  border-radius: 15px;
  color: #e5e5e5 !important;
}

.tour_vis {
  background-color: rgba(255, 255, 255, .7);
  border-radius: 15px;
  color: #40a040 !important;
}

.btn_act {
  font-size: unset !important;
}

.copy_btn {
  cursor: pointer;
  color: #454545;
  font-size: .85em !important;
}

.open_link {
  color: black !important;
  text-decoration: none !important;
}

.insert_image {
  align-items: center;
  margin: 0 !important;
  padding: 1em 0;
}

.change_cimage {
  font-size: 13px;
}

.comp_image {
  max-width: 100%;
  max-height: 150px;
  border-radius: 10px;
}

.url_box {
  margin: .25em !important;
  width: 100% !important;
}

.prewrap {
  white-space: pre-wrap;
}

.ws_pre {
  white-space: pre;
}

.men_it:hover {
  cursor: pointer;
  background-color: #eeeeee;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}

.stepper {
  width: 100%;
  margin: 1em 0;
}

.step {
  color: rgba(0, 0, 0, .5) !important;
  z-index: 2;
}

.ok_step {
  color: darkseagreen !important;
  z-index: 2;
}

.dot_stepper {
  position: relative;
  top: 2px;
}

.line_stepper {
  height: 6px;
  width: 100%;
  z-index: 1;
  border-bottom: 1px solid rgba(0, 0, 0, .33);
  position: relative;
  top: .5em;
}

.ls_fill_ok {
  border-bottom: 5px solid darkseagreen !important;
  cursor: pointer;
}

.ls_empty {
  background-color: rgba(255, 255, 255, .25);
}

.btn_create {
  font-size: 30px !important;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
}

.link_blue {
  cursor: pointer;
  color: $link-color;
}

.help_tooltip {
  margin: 0 0 0 .333em;
  font-size: 1.25em;
  color: $link-color;
  cursor: pointer;
}

.upl_logo {
  cursor: pointer;
  color: rgba(63, 81, 181, 1);
}

.img_preview {
  width: 100%;
  height: 150px;
  object-fit: contain;
}

.t_insert_logo {
  cursor: pointer;
  color: rgba(63, 81, 181, 1);
  margin: 0 !important;
}

.change_city_img {
  font-size: .8em;
  min-width: 200px;
  text-align: center;
}

.fw6 {
  font-weight: 600 !important;
}

.fw5 {
  font-weight: 500 !important;
}

.font15 {
  font-size: 1.5em !important;
}

.font2 {
  font-size: 2em !important;
}

.z-10 {
  z-index: 10 !important;
}

.move_cursor {
  cursor: move;
}

.msg-icon-action {
  color: rgba(0, 0, 0, .65) !important;
}

.gray {
  color: rgba(0, 0, 0, .65);
}

.grey_ico {
  color: #565656;
  padding: .125em 0;
  border-radius: 100%;
}

.sm_ico {
  width: 20px !important;
  height: 20px !important;
  font-size: 20px !important;
}

.d-sm-contents {
  display: none !important;
}

.d-contents {
  display: contents !important;
}

.darkorange {
  color: darkorange !important;
}

.firebrick {
  color: firebrick !important;
}

.limegreen {
  color: limegreen !important;
}

.green {
  color: green !important;
}

.bg_firebrick {
  background-color: rgba(178, 34, 34, .5) !important;
}

.po_on {
  color: #40a040 !important;
}

.po_off {
  color: rgba(0, 0, 0, .333) !important;
}

.warn {
  color: white;
  font-weight: 500;
  background-color: rgb(219, 64, 64);
  border-radius: 20px;
  padding: 0 .333em;
}

.ico_check {
  font-size: 12px;
  font-weight: 900;
  position: absolute;
  bottom: -2px;
  width: 12px !important;
  height: 12px !important;
  right: -3px;
  border-radius: 100%;
  color: white;
  border: 1px solid white;
}

.ico_sent {
  color: rgb(255, 215, 0) !important;
}

.ico_sched {
  color: darkorange !important;
}

.verified,
.ico_confirmed {
  color: rgb(0, 128, 0) !important;
}

.ico_conf {
  background-color: rgb(0, 128, 0) !important;
}

.ico_pend {
  background-color: rgb(255, 215, 0) !important;
}

.ico_ora {
  background-color: darkorange !important;
}

.ico_red {
  background-color: firebrick;
}

.ico_hotel,
.ico_guss,
.ico_rest,
.ico_trfr,
.ico_free {
  background-color: $ube;
}

.ico_opt {
  background-color: $butter;
}

.ico_gener,
.ico_other,
.ico_bday {
  background-color: $mojito;
}

.ico_incid {
  background-color: $aperol;
}

.ico_safe,
.ico_priva {
  background-color: rgba(0, 0, 0, .666);
}

.italic {
  font-style: italic !important;
}

.search_cont {
  position: absolute;
  top: 0;
  left: 6em;
  width: 75%;
  background-color: white;
  z-index: 10;
  border-radius: 10px;
  font-size: 12px
}

.tdo_sear_item {
  border: 1px solid #dedede;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  background-color: white;
  padding: 0;
  margin: 0 .25em 1em .25em;
  min-width: 200px;
  max-width: 200px;
  cursor: pointer;
}

.mli:hover {
  background-color: #eeeeee;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}

.spin_load {
  position: absolute !important;
  right: 0;
  transform: translate(-25%, -50%);
  top: 50%;
}

.gen_pass {
  cursor: pointer;
  font-size: 80%;
  font-weight: 400;
  padding: .1em .5em;
  border-radius: 4px;
}

.gen_pass:hover {
  background-color: rgb(240, 240, 240);
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

.gen_sel {
  background-color: rgb(240, 240, 240);
}

.w-xl-50 {
  width: 50%;
}

.width80 {
  width: 80%;
}

.snack5 {
  margin-bottom: 5px !important;
}

.rotating {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  -ms-animation: rotating 1s linear infinite;
  -o-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
  cursor: default !important;
}

.act_filter {
  color: rgb(63, 81, 181) !important;
  font-weight: 600;
}

.weather_ico {
  width: 40px;
  height: 40px;
}

.max_temp {
  color: #202124 !important;
}

.min_temp {
  color: #70757a !important;
}

.fileover {
  animation: shake 1s;
  animation-iteration-count: infinite;
}

@-webkit-keyframes rotating /* Safari and Chrome */
{
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes opa {
  from {
    opacity: .6;
  }
  to {
    opacity: 1;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, .9) translateY(0);
  }
  30% {
    transform: scale(.9, 1.1) translateY(-27px);
  }
  50% {
    transform: scale(1.05, .95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px 0 rgba(63, 81, 181, .3);
  }
  100% {
    box-shadow: 0 0 10px 5px rgba(63, 81, 181, .7);
  }
}

@media (max-width: map-get($grid-breakpoints, lg)) {
  .w-lg-50 {
    width: 50%;
  }
}

@media (max-width: map-get($grid-breakpoints, md)) {
  .footer-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: white;
    box-shadow: 0 -5px 5px -5px #888888;
    z-index: 3;
  }
  .footer-actions {
    padding: 0 .5em;
  }
  .planafy_loading {
    max-width: 222px;
    min-width: 200px;
    top: 20%;
  }
  .logo-loading {
    max-width: 175px;
    min-width: 150px;
    top: 25%;
  }
  .auth__container,
  .component__container,
  .createtour__container,
  .settings__container,
  .home__container,
  .tourview__container {
    margin: 0 .5em;
  }
  .search_cont {
    width: 77%;
  }
  .w-md-100 {
    width: 100%;
  }
  .w-md-75 {
    width: 75%;
  }
  .w-md-50 {
    width: 50%;
  }
}

@media (max-width: map-get($grid-breakpoints, sm)) {
  .d-sm-contents {
    display: contents !important;
  }
  .d-contents {
    display: none !important;
  }
  .w-sm-un {
    width: unset !important;
  }
  .w-sm-100 {
    width: 100%;
  }
  .small_sm {
    font-size: 80% !important;
    font-weight: 400;
  }
  .planafy_loading {
    max-width: 160px;
    min-width: 150px;
    top: 20%;
  }
  .logo-loading {
    margin: 0 2em;
    max-width: 125px;
    min-width: 100px;
    top: 25%;
  }
  .refresh_icon {
    margin-right: .333em;
  }
  body {
    background-repeat: repeat-y !important;
  }
  .search_cont {
    left: 4em;
    width: 66%;
  }
  .insert_image {
    padding: .5em 0;
  }
  .weather_ico {
    width: 35px !important;
    height: 35px !important;
  }
}
